<template>
  <div>
    <div class="box">
      <statside :qid="qid"></statside>
      <!-- style="position:fixed; z-index:1" -->
      <router-view v-on:qid="getqidFromChild"/>
    </div>
  </div>
</template>

<script>
import statside from './statside'
export default {
  data() {
    return {
      qid: '',
    }
  },
  components:{
    statside
  },
  methods: {
    getqidFromChild(qid) {
      this.qid = qid;
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.box {
  display: flex;
  margin-top: 20px;
}

</style>
